<template>
  <div style="min-width: 1440px; max-width: 1920px; margin: 0 auto">
    <Header />
    <Banner :bannerUrl="bannerUrl" :subTilte="subTilte" :content="content" v-if="!hideBanner" />
    <NewBanner :imageUrl="imageUrl" :title="title" :contentX="contentX" v-if="show" />
    <LeftNav v-if="!hideLeft" />
    <RightNav />
    <slot />
    <NavFooter />
  </div>
</template>

<script>
import Header from "./components/Header/Header";
import LeftNav from "./components/LeftNav/LeftNav";
import Banner from "./components/Banner/Banner";
import NewBanner from "./components/NewBanner/NewBanner";
import NavFooter from "./components/Footer/Footer";
import RightNav from "./components/RightNav/RightNav";
export default {
  props: ["bannerUrl", "subTilte", "content", "hideLeft", "hideBanner", 'imageUrl', 'title', 'contentX', 'show'],
  components: { Header, LeftNav, Banner, NavFooter, RightNav, NewBanner },
  setup() { },
  methods: {
    toHelpCenter() {
      this.$parent.toHelpCenter();
    },
  },
};
</script>