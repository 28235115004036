<template>
  <div class="header_wrap">
    <div class="logo" style="cursor: pointer" @click="goToIndex">
      <img :src="logoImg" />
    </div>

    <div class="header_right">
      <!-- <div class="search_icon_wrap">
        <div class="search_icon" style="cursor: pointer">
          <img :src="searchIcon" style="width: 25px; height: 25px" />
        </div>
      </div> -->

      <div class="header_console_btn_wrap" @click="goToLogin()">
        <div class="header_console_btn" style="cursor: pointer">
          <img
            :src="noticeIcon"
            style="width: 25px; height: 25px; margin: 0 15px"
          />
          <span>控制台</span>
        </div>
      </div>

      <div class="header_line"></div>

      <div class="header_btn_wrap" v-if="!haveToken">
        <div
          class="header_login_btn"
          style="cursor: pointer"
          @click="goToLogin()"
        >
          登录
        </div>
        <div
          class="header_register_btn"
          style="cursor: pointer"
          @click="goToRegister()"
        >
          立即注册
        </div>
      </div>
    </div>
  </div>

  <div class="header_li_wrap">
    <div class="headcer_li_logo">海衡招标阳光集采平台</div>
    <div class="header_li_right">
      <ul>
        <!-- width:${100 / listData.length}%; -->
        <li
          v-for="item in listData"
          :key="item.code"
          class="header_li_right_item"
          :style="`cursor:pointer`"
          @click="pushRouter(item)"
        >
          <p>
            {{
              item.categoryName == "新闻中心页" ? "新闻中心" : item.categoryName
            }}
          </p>
          <!-- <img :src="activeLiIcon" :style="activePage == item.url ? '' : 'visibility: hidden'" /> -->
          <img
            :src="activeLiIcon"
            :style="activePageUrl == item.link ? '' : 'visibility: hidden'"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import imgData from "@/data.js";
import { reactive, computed } from "vue";
import { useRouter } from "vue-router";
import { mainStore } from "@/store";
import { storeToRefs } from "pinia";
export default {
  setup() {
    const store = mainStore();
    const { navBar } = storeToRefs(store);
    const activePageUrl = computed(() => {
      return storeToRefs(store).activePageUrl;
    });

    const listData = computed(() => storeToRefs(store).navBar);
    const router = useRouter();
    const goToIndex = () => {
      store.setactivePageUrl("/index");
      router.push({ path: "/index" });
    };
    const pushRouter = (item) => {
      const { categoryType, link } = item;
      if (categoryType == 1) {
        const path = link == "/" ? "/index" : link;
        router.push({ path });
        store.setactivePageUrl(path);
      } else {
        window.open(link, "_blank");
      }
    };
    const data = reactive({
      listData,
      activePageUrl,
    });
    return {
      ...data,
      ...imgData,
      pushRouter,
      activePage: "/index",
      haveToken: false,
      goToIndex,
    };
  },
  created() {
    let href = window.location.href;
    let origin = window.location.origin + "/";
    this.activePage =
      href.replace(origin, "") == "BiddingInfo"
        ? ""
        : "/" + href.replace(origin, "");

    let arr = document.cookie.split(";");
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].indexOf("saber-access-token") !== -1) {
        this.haveToken = true;
      }
    }
  },
  methods: {
    goToLogin() {
      // console.log(process.env);
      window.open(process.env.VUE_APP_SEVERCE_PLATFORM, "_blank");
      // location.href = process.env.VUE_APP_SEVERCE_PLATFORM;
    },
    goToRegister() {
      window.open(process.env.VUE_APP_SEVERCE_REGISTER, "_blank");
      // location.href = process.env.VUE_APP_SEVERCE_REGISTER;
    },
  },
};
</script>

<style >
@import url("./Header.css");
</style>